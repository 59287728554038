import React from 'react';

import Row from '../Container/row';
import Col from '../Container/column';

import loadLibrary from '../Loadable/library';

const Img = loadLibrary('gatsby-image');

export default function ImageText(props) {
  const { data } = props;
  const { image, content } = data;
  return (
    <div style={{ marginTop: `5.625rem` }}>
      <Row align="center" justify="space-between">
        <Col
          xl={{
            width: `${image.width}%`,
            order: image.alignment === 'left' ? 1 : 2,
          }}
          className="image"
        >
          <Img
            image={image.file.localFile.childImageSharp.gatsbyImageData}
            alt={image.file.alt_text ? image.file.alt_text : image.file.title}
          />
        </Col>
        <Col
          xl={{
            width: `${95 - image.width}%`,
            order: image.alignment === 'left' ? 2 : 1,
          }}
          className="text"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Row>
    </div>
  );
}
